@import './components/styles/global';

* {
  box-sizing: border-box;
  overscroll-behavior: none;
  scrollbar-width: none; /* Makes the scrollbar smaller */
  scrollbar-color: $black-700; /* Thumb color and track color */
}

::-webkit-scrollbar-track {
  background: transparent; /* Hide the scrollbar track */
}

::-webkit-scrollbar {
  display: none;
}

button,
a {
  text-decoration: none;
  color: $green-900;
}

ul,
li {
  list-style: none;
  text-indent: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
  unicode-bidi: isolate;
}

html {
  overflow-x: hidden;
  letter-spacing: -0.64px;
  overscroll-behavior: none;
  background-color: $black-200;
  color: $black-700;
  scrollbar-width: none;
}

body {
  scrollbar-width: none;
  padding: 0;
  margin: 0;
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
  color: $black-700;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.appWrapper {
  display: grid;
  grid-template-columns: 56px 38% 1fr; // Define the columns
  grid-template-rows: 1fr; // Single row
  grid-template-areas: 'toolbar navWindow mainBody'; // Define the areas
  height: 100dvh; // Full height
  width: 100dvw; // Full width
  overflow: hidden;

  @include md {
    grid-template-columns: 1fr;
    grid-template-areas:
      'navWindow'
      'mainBody';
  }

  @include for-phone-only {
    grid-template-columns: 1fr;
    grid-template-areas:
      'navWindow'
      'mainBody';
  }
}

.appContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.mainContent {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.navWindow {
  grid-area: navWindow;
  overflow-y: scroll;
  border-right: 1px solid $black-700;
  height: 100dvh;
  width: 100%;
  @include md {
    border-right: none;
  }
}

.mainBody {
  grid-area: mainBody;
  overflow-y: scroll;
  height: 100vh;
}

.songPageContainer {
  grid-area: mainBody;
  overflow-y: scroll;

  @include md {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }
}

.appBody {
  width: 100%;
  @include for-phone-only {
  }
}

.appBodyBumper {
  @media (max-width: 900px) {
    display: none;
  }
}

.toolbar {
  grid-area: toolbar;
}

.hide-on-mobile {
  display: block;

  @include sm {
    display: none;
  }

  @include md {
    display: none;
  }

  @include lg {
    display: none;
  }

  @include xl {
    display: none;
  }
}

// src/index.scss or a new file like src/styles/loading.scss

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  border: 8px solid rgba($yellow-300, 0.1);
  border-top: 8px solid $yellow-300;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 100px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-container {
  width: 100dvw;
  height: 100dvh;
  background-color: $yellow-300;
  display: flex;
  justify-content: center;
  align-items: center;
}
