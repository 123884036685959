@import '../styles/global';

.songPageContainer {
  width: 100%;
  background-color: $yellow-300;
}

.navBar {
  width: 100%;
  height: 56px;
  margin: 0 auto;
  padding: 0 0 0 16px;
  background-color: $yellow-300;
  position: sticky;
  top: 0;
  z-index: 20;
  border-bottom: 1px solid $black-700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.navBarTitle {
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding-right: 16px;
  overflow: hidden;
  position: relative;
}

.navBarTitleContent {
  width: 100%;
  height: 56px;
  position: relative;
  display: flex;

  align-items: center;
}

.initial {
  .defaultTitle {
    transform: translateY(0);
    opacity: 1;
  }

  .scrolledTitle {
    transform: translateY(28px);
    opacity: 0;
  }
}

.scrolled {
  .defaultTitle {
    transform: translateY(-28px);
    opacity: 0;
  }

  .scrolledTitle {
    transform: translateY(0);
    opacity: 1;
  }
}

.defaultTitle {
  width: 100%;

  height: 56px;
  font-weight: 600;
  position: relative;
  display: flex;
  align-items: center;
  transition:
    transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94),
    opacity 0.15s ease;
}

.scrolledTitle {
  position: absolute;
  width: 100%;
  transition:
    transform 0.3s cubic-bezier(0.19, 1, 0.22, 1),
    opacity 0.15s ease-in-out;
}

.navBarSong,
.navBarArtist {
  transition: all 0.3s ease;
}

.navBarSong {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.64px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.navBarArtist {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.52px;
}

.navBarButtons {
  display: flex;
  flex-direction: row;
  min-width: 112px;
}

.closeButton,
.skipButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  min-width: 56px;
  height: 56px;
  background-color: transparent;
  color: $black-700;
  font-size: 13px;
  line-height: 24px;
  cursor: pointer;
  border-left: 1px solid $black-700;

  &:hover {
    background-color: rgba($yellow-300, 0.2);
  }
}

.container {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid $black-700;
  background: $black-100;
  overflow: hidden;
  box-shadow: 2px 2px 0px 0px #404040;
  margin: 0 auto;
}

.pageHeader {
  margin: 16px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header {
  width: 100%;
  background-position: center;
  background-size: auto;
  border-bottom: 1px solid $black-700;
  aspect-ratio: 16 / 9;
  & > .plyr--video .plyr__controls {
    max-height: 56px !important;
  }
}

.body {
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.songInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.title {
  color: $black-700;
  font-family: Inter, sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -2px;
  max-width: 560px;
  margin: 0;
}

.artist {
  color: $black-700;
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.52px;
}

.avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: $black-800;
  border: 1px solid $black-700;
}

.postDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.userLink {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.postedByStack {
  display: flex;
  flex-direction: column;
}

.postedBy {
  color: $black-700;
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.52px;
}

.postedAt {
  color: $black-700;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.52px;
}

.descriptionSection {
  width: 100%;
  justify-content: space-around;
}

.descriptionBox {
  margin: 16px;
  position: relative;
  padding-top: 40px;
  padding-bottom: 16px;

  .descriptionBoxLabel {
    position: absolute;
    top: 8px;
    left: 0;
    font-size: 13px;
    line-height: 16px;
    color: $black-700;
  }
}

.description {
  margin: 0;
  color: $black-700;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.64px;
  white-space: pre-wrap;
}

.utilButtons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
  margin: 16px;
}

.metadata {
  width: 100%;
  border-top: 1px solid $black-800;
  border-bottom: 1px solid $black-800;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.modalTitle {
  margin-bottom: 24px;
}

.metaCount {
  border: none;
  background: transparent;
  font-size: 16px;
  line-height: 24px;
  color: $black-700;
  padding: 0;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}
.postBodyContainer {
  height: calc(100vh - 56px);
  overflow-y: auto;
  overscroll-behavior: contain;
  width: 100%;
  position: relative;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }
}
.postBody {
  width: 100%;
  overflow-x: hidden;
  max-width: 960px;
  margin: 0 auto;
  padding: 16px;
  background-color: $yellow-300;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    width: 0;
  }
  @include for-phone-only {
    padding-bottom: 104px;
  }
}

.likersList {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mobileCloseButton {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1001;
  padding: 10px;
  background-color: $yellow-300;
  border: 1px solid $black-700;
  border-radius: 5px;
  font-weight: bold;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  max-height: 56px;

  & > svg {
    transform: rotate(90deg);
    opacity: 0.5;
  }
}

.player {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  width: 100%;
  background: #000;
  aspect-ratio: 16 / 9;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.loadingContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $yellow-300;
  gap: 16px;
}

.loadingSpinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(23, 23, 23, 0.2);
  border-radius: 50%;
  border-top-color: $black-700;
  animation: spin 1s linear infinite;
}

.loadingText {
  color: $black-700;
  font-size: 16px;
  font-weight: 500;
}

.error {
  width: 100%;
  padding: 16px;
  background-color: #fee2e2;
  color: #dc2626;
  text-align: center;
  border: 1px solid #dc2626;
  border-radius: 4px;
  margin: 16px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  transition: all 0.3s ease;
  z-index: 1000;
}

.navbar.show-details {
  background-color: #f8f8f8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.song-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scrollMarker {
  height: 1px;
  width: 100%;
  background: transparent;
}

.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loadingText {
  margin-top: 10px;
  font-size: 18px;
  color: #333;
}
